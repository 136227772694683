(function($) {

    'use strict';
    var CatalogAjax = function(element, options) {
        this.$element = $(element);
        this.options = options;
        this.$filters = $('[data-catalog-filters]');
        this.$filterTop = $('[data-catalog-filter-top]');
        this.$products = $('[data-catalog-products]');
        this.$breadcrumb = $('[data-catalog-breadcrumb]');
        this.$banner = $('[data-catalog-banner]');
        this.$trackingCatalog = $('[data-catalog-tracking]');
        this.$footer = $('[data-catalog-footer]');
        this.$slider = $('[data-catalog-slider]');
        this.location = window.location.href;
        this.initialPop = ('state' in window.history && window.history.state !== null);
        this.initialLocation = this.location;

        this.$element.on('click', '[data-catalog=ajax] a', $.proxy(this,'onClick'));
        this.$slider.find('.slider-input').on('change', $.proxy(this, 'onSliderChange'));
        window.onpopstate = $.proxy(this, 'onPopState');
    }

    CatalogAjax.DEFAULTS = {};

    CatalogAjax.prototype.onSliderChange = function () {
        var index = $('.slider-input:checked').index('.slider-input')
            , $slide = this.$slider.find('article a').eq(index);

        $slide.trigger('click');
    };

    CatalogAjax.prototype.onClick = function(e) {
        var target = $(e.currentTarget);
        if (target.attr('href') == '/')
            return;

        if (target.data('catalog-ajax-disabled') || target.attr('disabled') === 'disabled') {
            return;
        }

        e.preventDefault();

        if (!target.hasClass('is-disabled') && target.attr('href')) {
            this.initialPop = true;
            this.eventType = e.type;
            var facet_active = target[0].parentNode.parentNode.parentNode.parentNode;
            var data_facet = facet_active.getAttribute("data-facet");
            var value = data_facet === 'preco' ? target[0].getAttribute('value').toLowerCase() : target[0].innerText.toLowerCase();
            this.load(target.attr('href'), data_facet, value);
        }

        this.scrollTop(target);
    }

    CatalogAjax.prototype.scrollTop = function(target) {
        if (target.data('scroll-top')) {
            $(document).trigger('dft.go-to-top');
        }
    }

    CatalogAjax.prototype.onPopState = function(e) {
        if (!this.initialPop && window.location.href == this.initialLocation) return;
        this.initialPop = true;

        e.preventDefault();
        this.eventType = e.type;
        this.load(window.location.href);
    }

    CatalogAjax.prototype.load = function(url, filter, value) {
        if (this.ajaxActive) return ;

        this.ajaxActive = true;
        this.loading();

        this.location = url;

        var precoFilter = '';

        if (document.querySelector('div#price[data-facet="preco"]')) {
            precoFilter = document.querySelector('div#price[data-facet="preco"]').getAttribute('data-label');
        }

        var callUrl = this.location + ((this.location.indexOf("?") > -1) ? '&' : '?') + 'ajax=true&cat-pwa=0&campwa=0';
        $.getJSON(callUrl, {'filter_selected': filter, 'value': value}, $.proxy(this, 'loaded'))
            .done(function (facets) {
                $.each(facets, function (i, body) {
                    if (body && body.result && body.result.activeFacets) {
                        $.each(body.result.activeFacets, function (i, facet) {
                            if (facet.filter_selected === true) {
                                var label = facet.name;
                                var options_value = facet.value;
                                var result_quantity = body.result.total;
                                if (getDataLayer(label, options_value) === false) {
                                    var data = {'label': label, 'options_value': options_value, 'result_quantity': result_quantity, 'filter': precoFilter};
                                    sendDataLayer(data);
                                    facet.filter_selected = false;
                                }
                            }
                        });
                    }
                });
            })
            .fail(function (jqxhr, textStatus, error) {
                $.proxy(this, 'loaded')
                var err = textStatus + ", " + error;
                console.log("Request Failed: " + err);
            }
            );
    }

    function mountLayer(type, layer) {
        const gaVersion = {
            "ga3": {
                'event': 'select_content_ga3',
                'category': 'filter',
                'action': 'click',
                'label': layer.label,
                'options_value': layer.options_value,
                'results_quantity': layer.result_quantity
            },
            "ga4": {
                'event': 'select_content_ga4',
                'content_type': 'filter',
                'item_id': layer.label,
                'options_value': layer.options_value,
                'results_quantity': layer.result_quantity
            }
        };

        if (layer.label === "preco" && layer.filter !== "") {
            gaVersion.ga3.label = layer.filter;
            gaVersion.ga4.item_id = layer.filter;
        }

        return gaVersion[type];
    }

    function sendDataLayer(fields) {
        window.dataLayer = window.dataLayer ? window.dataLayer : [];
        window.dataLayer.push(mountLayer('ga3', fields));
        window.dataLayer.push(mountLayer('ga4', fields));
    }

    function getDataLayer(label, options_value) {
        var sendDataLayer = false;
        if (window.dataLayer.length > 0) {
            $.each(window.dataLayer, function (i, data) {
                if (data.item_id === label && data.options_value === options_value) {
                    sendDataLayer = true;
                    return false;
                }
            });
        }

        return sendDataLayer;
    }
    CatalogAjax.prototype.loaded = function(response) {
        this.ajaxActive = false;
        if (typeof response.body == 'undefined') {
            this.$products.find('[data-catalog-loading]').remove();
            return;
        }

        if (response.body.noResults != '' || response.body.total == 0) {
            window.location.href = this.location;
            return;
        }

        this.updateLocation();
        this.renderProducts(response.body.products);
        this.renderFilters(response.body.filter);
        this.renderBreadcrumb(response.body.breadcrumb);
        this.renderFilterTop(response.body.selectionOptions);
        this.renderFooter(response.body.footer);
        this.renderTracking(response.body.tracking);

        if(this.$slider.length == 0) {
            this.renderBannerFull(response.body.cms);
        }

        $(document).trigger('dft.catalog-ajax.loaded');
    }

    CatalogAjax.prototype.updateLocation = function() {
        if (this.eventType !== 'popstate')
            window.history.pushState(null, null, this.location);
    }

    CatalogAjax.prototype.renderProducts = function(html) {
        this.$products.empty();
        this.$products.html($(html).html());
        addReviewTrustVox();
        this.$products.find('[data-lazyload]').lazyload({
            threshold : 600,
            effect : 'fadeIn',
        });
    }

    function toggleFreeShipping(url) {

        const toggleComponent = document.querySelector('toggle-switch-component[name="toggle-switch-free-shipping"]');
        const queryString = new URL(url).search;
        const urlParams = new URLSearchParams(queryString);
        const freeShipping = urlParams.get('free_shipping');
        const sellerValue = urlParams.get('seller');

        if (sellerValue !== null) {
            const freightContainer = document.getElementById('free-freight-container');
            if (freightContainer) {
                freightContainer.style.display = 'none';
            }
        }
        if (freeShipping === 'true' && toggleComponent.shadowRoot) {
            const checkboxElement = toggleComponent.shadowRoot.querySelector('input[type="checkbox"]');
            const accordionItem = document.querySelector('accordion-item[data-facet="vendidopor"]');
            accordionItem.style.display = 'none';
            if (checkboxElement) {
                checkboxElement.checked = true;
            }
        }
    }

    CatalogAjax.prototype.renderFilters = function(html) {
        this.$filters.html(html).find("#free-freight-container").removeClass('hide');
        toggleFreeShipping(window.location.href);
        attachToggleSwitchListener();
    }

    CatalogAjax.prototype.renderBreadcrumb = function(html) {
        this.$breadcrumb.html(html);
    }

    CatalogAjax.prototype.renderFilterTop = function(html) {
        var parent = this.$filterTop;
        parent.empty();
        parent.append(html);
    }

    function addReviewTrustVox() {
        var requestOptions = {
            method: "GET",
            redirect: "follow",
        }

        var productsBox = document.getElementsByClassName("product-box");
        var url = "https://trustvox.com.br/widget/shelf/v2/products_rates?";

        for (var i = 0; i < productsBox.length; i++) {
            var sku = productsBox[i].dataset.sku;
            var consulted = productsBox[i].dataset.consulted;
            if(productsBox[i].dataset.sku != undefined && !consulted)
            {
                var trustvoxId = productsBox[i].dataset.rating;
                var urlMontada = url + 'store_id=' + trustvoxId + '&codes[]=' + sku;
                productsBox[i].dataset.consulted = true;
                fetch(urlMontada, requestOptions)
                    .then(function (response) {
                        return response.json()
                    })
                    .then(function (result) {
                        var average = result.products_rates[0].average;
                        if (average > 0 && productsBox[i] !== undefined) {
                            var ratingElement =
                                productsBox[i].getElementsByClassName(
                                    "rating_trustvox"
                                )[0];
                            ratingElement.style.visibility = "visible";
                            ratingElement.getElementsByTagName(
                                "small"
                            )[0].innerHTML = average;
                        }
                    }).catch(function (error) {
                    console.log("error", error)
                });
            }

        }
    }

    CatalogAjax.prototype.renderBannerFull = function(cmsContent) {
        var bannerFull = '';
        if (cmsContent.length > 0 && cmsContent[0].items.banner_full) {
            bannerFull = cmsContent[0].items.banner_full;
        } else if (cmsContent.items && cmsContent.items.banner_full) {
            bannerFull = cmsContent.items.banner_full;
        }
        this.$banner.html(bannerFull);
    }

    CatalogAjax.prototype.renderTracking = function(cmsContent) {
        if(cmsContent) {
            if($('body').find(".tracking-ajax").length == 0) {
                $('body').append('<div class="tracking-ajax">'+ cmsContent.items.text +'</div>');
            } else {
                $('.tracking-ajax').empty();
                $('.tracking-ajax').append(cmsContent.items.text);
            }
        }
    }

    CatalogAjax.prototype.renderFooter = function(html) {
        this.$footer.html(html);
    }

    CatalogAjax.prototype.loading = function() {
        this.$products.append(this.getLoadingTemplate());
    }

    CatalogAjax.prototype.getLoadingTemplate = function() {
        if (typeof this.template !== 'undefined') return this.template.clone();

        var el = this.$products.find('[data-catalog-loading]');
        this.template = el.clone().addClass('is-active');
        el.remove();
        return this.template.clone();
    }

    $.fn.catalogAjax = function(option) {
        return this.each(function() {
            var $this   = $(this);
            var data    = $this.data('dft.catalog-ajax');
            var options = $.extend({}, CatalogAjax.DEFAULTS, $this.data(), typeof option == 'object' && option);

            if (!data) $this.data('dft.catalog-ajax', (data = new CatalogAjax(this, options)));
            if (typeof option == 'string') data[option]();
        });
    }

    $(document).on('dft.catalog-ajax.load', function() {
        if (window.history.pushState) {
            $('body').catalogAjax();
        }
        $("#free-freight-container").removeClass('hide');
    })

    toggleFreeShipping(window.location.href);

})(jQuery);
