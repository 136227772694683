(function () {

    var topic;
    var problem;
    var problemDetail;
    var topicProblems;
    var topicProblemsDetails;
    var topicClass;
    var topicProblemsId;
    var topicProblemsDetailsId;
    var textSelected;
    var excludeAccount;

    $(document).on('click', '#step_1', function (e) {
        if(!$('#step_1').hasClass('active')) {
            $('#step_2').removeClass('active');
            $('.faq-icon-mail').toggleClass('hide');
            $('.topic-required, .problem-required, .detail-required, #support_email').addClass('hide');
            $('#step_1').find('.mainContent-topics-item').removeClass('error');
            $('#step_1').toggleClass('active');
            $('.custom-select').removeClass('error');
            $('.mainContent-problems-item').removeClass('error');
            $('#step_1').find('.text-selected').addClass('hide');
            $("#video-faq-change").addClass('hide')
        }
        $('#step_2').removeClass('no-border');
        $('#step_2 > .mainContent-step-content').addClass('hide');
    });

    $(document).on('click', '#step_2', function (e) {
        if($('#step_1').hasClass('active')) {
            $('#step_1').find('.mainContent-topics-item').addClass('error');
            $('.topic-required').removeClass('hide');
        }
    });

    $(document).on('click', '.mainContent-step-content', function (e) {
        e.stopPropagation();
    });

    $(document).ready(function(){
        excludeAccount = $("#topic_other").data('exclude');
        valideExcludeAccountIsActive();
    });

    // section 1: topics
    $(document).on('click', '.mainContent-topics-item', function (e) {
        topic = e.currentTarget.id;
        topicProblems = topic + '_problems';
        topicProblemsDetails = topicProblems + '_details';
        topicClass = '.' + topic;
        topicProblemsId = '#' + topicProblems;
        topicProblemsDetailsId = '#' + topicProblemsDetails;

        setItemSelected(e);
        $('.faq-icon-mail').toggleClass('hide');
        $('#support_email').addClass('inactive');
        $('.mainContent-support, .problems, .mainContent-problems-detail').addClass('hide');
        $('#support_email').removeClass('hide');
        $(topicClass).addClass('hide');
        $('#step_1').removeClass('active');
        $('#step_2').removeClass('hide').addClass('active');
        $('.custom-radio > input[type="radio"]').removeClass('error').prop('checked', false);
        $('.mainContent-problems-detail select').addClass('hide').removeClass('active').prop('disabled', true);
        $(topicProblemsDetailsId + ' select:first').removeClass('hide');
        $('#step_2').addClass('no-border');
        $('#step_2 > .mainContent-step-content').removeClass('hide');
        $('.text-selected.topics').removeClass('hide');

        if($(document).find(topicClass).length !== 0) {
            $(topicProblemsDetailsId).removeClass('hide');
            $(topicProblemsId).removeClass('hide');
            $(topicClass).removeClass('hide');
        }
    });

    $('input:radio[name="problem"]').change(function(e) {
        $('#support_email').addClass('inactive');
        $('.mainContent-support, .problem-required, .detail-required').addClass('hide');
        $(topicProblemsDetailsId).removeClass('hide');
        $('.custom-radio > input[type="radio"]').removeClass('error');
        $(e.currentTarget).addClass('active');
        $(topicProblemsDetailsId + ' select').addClass('hide').removeClass('active');
        $('.custom-select').removeClass('error');
        $('input[name="FaqContactForm[subject]"]').val(textSelected);

        problem = e.currentTarget.id;
        problemDetail = problem + '_detail';
        problemDetailId = '#' + problemDetail;

        if($(document).find(problemDetailId).length !== 0) {
            $('#support_email').removeClass('hide');
            $(problemDetailId).removeClass('hide').prop("disabled", false);
            $(problemDetailId + ' > option:first').prop("selected", true);
        } else {
            $('.mainContent-problems-detail').addClass('hide');
            if($(document).find('#' + problem + '_default').length !== 0) {
                $('#' + problem + '_default').removeClass('hide');
            } else {
                $('#support_email').removeClass('hide').removeClass('inactive');
            }
        }
    });

    $(document).on('change', '.mainContent-problems-detail select', function(e) {
        $('#support_email').removeClass('inactive');
        $('.custom-select').removeClass('error');
        $('.mainContent-support, .detail-required').addClass('hide');
        $('.mainContent-problems-detail select').addClass('active');
        if($(document).find('#' + this.value).length !== 0) {
            $('#' + this.value).removeClass('hide');
        } else {
            $('#support_email').removeClass('hide');
        }
        verifySelectForDeleteAccount(e);
    });

    function verifySelectForDeleteAccount(event) {
        var getSeletTag = event.currentTarget.id;
        if (getSeletTag == "topic_other_problem_1_detail") {
            var selectOption = $('#topic_other_problem_1_detail').val();
            if (selectOption == "support_email") {
                var textDeleteAccountSelected = 'OUTROS - Exclusão de Cadastro';
                $('input[name="FaqContactForm[subject]"]').val(textDeleteAccountSelected);
            }else{
                $('input[name="FaqContactForm[subject]"]').val(textSelected);
            }
        }
    }

    // send email
    $(document).on('click', '.link-support-email', function (e) {
        $('.mainContent-support').addClass('hide');
        $('#support_email').removeClass('hide').removeClass('inactive');
    });

    $(document).on('click', '#form-faq-contact button[type="submit"]', function(e) {
        if (hasProblemChosen()) {
            var reasonText = $("input[name='reason']:checked").next('label').text();
            var problemText = $(topicProblemsId + ' input[type="radio"]:checked').siblings('label').text();
            var problemDetailText = $(topicProblemsDetailsId + ' .custom-select.active').not('.hide').find(':selected').text();
            var messageText = $('#message').val();
            var messageFullText = $('#fullMessage');

            messageFullText.val("MOTIVO: " + reasonText + "\n" +
                "PROBLEMA: "  + problemText + "\n" +
                "DETALHE: " + problemDetailText + "\n" +
                "MENSAGEM: \n" + messageText
            );
            return;
        }
        e.preventDefault();
    });

    function hasReasonChosen() {
        var reaonsElementsChecked = $("input[name='reason']:checked");
        var haveReasonElements = (reaonsElementsChecked.length !== 0 );

        if (!haveReasonElements) {
            $('.reason-required').removeClass('hide');
            $(document).scrollTop(200);
            return false;
        }

        $('.reason-required').addClass('hide');
        return true;
    }

    function hasProblemChosen() {
        debug();
        var dontHaveProblemElements = ($(topicProblemsId).length === 0 );
        if(dontHaveProblemElements) {
            return true;
        }

        var dontHaveDetailsElemtents = (problem && $('#' + problem + '_detail').length === 0);
        if(dontHaveDetailsElemtents) {
            return true;
        }

        var dontHaveProblemsActive = ($(topicProblemsId + ' input[type="radio"]:checked').length === 0);
        var dontHaveDetailsActive = ($(topicProblemsDetailsId + ' .custom-select.active').not('.hide').find(':selected').length === 0);
        if(dontHaveProblemsActive) {
            console.log('no problem');
            $(topicProblemsId + ' input[type="radio"]').addClass('error');
            $(topicProblemsId + ' .problem-required').removeClass('hide');
            $(document).scrollTop(200);
            return false;
        } else if(dontHaveDetailsActive) {
            console.log('no problem detail');
            $(topicProblemsDetailsId + ' .custom-select').addClass('error');
            $(topicProblemsDetailsId + ' .detail-required').removeClass('hide');
            $(document).scrollTop(200);
            return false;
        }
        return true;
    }

    function debug() {
        console.log('topic: ', topic);
        console.log('problem: ', problem);
        console.log('problemDetail: ', problemDetail);
        console.log('topicProblems: ', topicProblems);
        console.log('topicProblemsDetails: ', topicProblemsDetails);
        console.log('topicClass: ', topicClass);
        console.log('topicProblemsId: ', topicProblemsId);
        console.log('topicProblemsDetailsId: ', topicProblemsDetailsId);
    }

    function setItemSelected(e) {

        if(e.currentTarget.id === "topic_change"){
            $("#video-faq-change").removeClass('hide')
        }

        var imageSelected = $(e.currentTarget).find('img').attr('src');
        textSelected = $(e.currentTarget).find('label').text();

        $('#icon_selected').attr('src', imageSelected);
        $('#label_selected').text(textSelected);
        $('input[name="FaqContactForm[subject]"]').val(textSelected);
    }

    function valideExcludeAccountIsActive() {
        if (excludeAccount != '1') {
            return;
        }

        $("#topic_other")[0].click();
        $("#topic_other_problem_1").prop("checked", true);
        $("#topic_other_problem_1").trigger("change");
        $("#order-nr-exclude-account").hide();
        $("#topic_other_problem_1_detail").val("support_email");
    }
})();
function openNewTab(href) {
    window.open(href);
}
